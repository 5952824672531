<template>
  <el-dialog :visible="formVisible" width="600px" :show-close="false">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>新增银行卡</span>
      </div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="开户银行" prop="bankName">
        <el-select v-model="ruleForm.bankName" placeholder="请选择开户银行名称" style="width: 100%">
          <el-option
            :label="item.label"
            :value="item.label"
            v-for="(item, index) in bankList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行卡号" prop="accountCode">
        <el-input v-model="ruleForm.accountCode"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { api } from '/src/api/base';
export const userSelfBankCardBind = api()('user.self.bank.card.bind.json');
export const getAllbanks = api()('getAllbanks.json');
export default {
  props: {
    formVisible: Boolean,
    userCode: String
  },
  data () {
    return {
      bankList: [],
      ruleForm: {
        bankName: '',
        accountCode: ''
      },
      rules: {
        bankName: [
          { required: true, message: '请选择开户银行名称', trigger: 'change' }
        ],
        accountCode: [
          { required: true, message: '请填写银行卡号', trigger: 'blur' }
        ]
      }
    };
  },
  async created () {
    const { data } = await getAllbanks();

    this.bankList = data;
  },
  methods: {
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.$emit('update:formVisible', false);
      this.$emit('close');
    },
    submit (form) {
      this.$refs[form].validate(async isValid => {
        if (isValid) {
          await userSelfBankCardBind({
            subject: this.userCode,
            bankName: this.ruleForm.bankName.replace(/\s/g, ''),
            accountCode: this.ruleForm.accountCode,
            branchBank: '',
            branchCode: '',
            bankArea: ''
          });
          this.$message.success('银行卡号添加成功！');
          this.closeDialog(form);
        }
      });
    }
  }
};
</script>

<style></style>
